






















































































































import Vue, {PropType} from "vue";
import {required, email, minLength, maxLength} from "vuelidate/lib/validators";
import vSelect from "vue-select";
import {mapActions, mapGetters} from "vuex";
import i18n from "@/i18n/i18n"

export default Vue.extend({
  name: "add-new-user-modal" as string,

  props: {
    fetchImages: Function as PropType<Function>,
    songorooAdmins: Boolean as PropType<Boolean>,
    clientzoneUsers: Boolean as PropType<Boolean>,
  },

  data() {
    return {
      email: "",
      firstName: "",
      lastName: "",
      jobPosition: "",
      isSubmitted: false,
      role: "",
      company: "",
    }
  },
  components: {
    vSelect,
  },

  //@ts-ignore
  validations: {
    email: { required, email },
    firstName: { required, minLength: minLength(3), maxLength: maxLength(20) },
    lastName: { required, minLength: minLength(3), maxLength: maxLength(20) },
    jobPosition: { required, minLength: minLength(3), maxLength: maxLength(40) },
    role: { required },
    company: { required },
  },

  computed: {
    ...mapGetters("userModule", {
      addUserLoader: "GET_ADD_USER_LOADER"
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    })
  },

  methods: {
    ...mapActions("userModule", {
      setUsers: "FETCH_USERS",
      validateUser: "VALIDATE_IF_USER_EXIST"
    }),
    async addUser(): Promise<void> {
      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const payload: object = {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        job_position: this.jobPosition,
        venue_owner_id: this.companyId,
        language: i18n.locale
      }

      await this.validateUser(payload);
      this.fetchImages(); // TODO: ?!

      this.email =  "";
      this.firstName = "";
      this.lastName = "";
      this.jobPosition = "";
      this.isSubmitted = false;

      this.$bvModal.hide("send-invitation");
    }
  }
})
